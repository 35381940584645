@media (max-width: 1100px) {
    .list-sponsors{
        grid-template-columns: repeat(5, 1fr);
    }
    .bg-dark h3{
        font-size: 20px;
    }
    .cards-color .card{
        min-height: auto;
        height: auto;
        max-height: 800px;
    }
    .img-blue h1{
        font-size: 60px;
    }
}
@media (min-width: 1100px) {
    .card-absolute{
        position: relative;
        z-index: 44;
        left: -15%;
    }
    .dropdown-menu.show {
        display: flex;
        width: 1200px;
        border-radius: 30px;
        padding: 30px;
        top: 80px;
    }
    .dropdown-left .dropdown-menu.show{
        left: -200px;
    }
}
@media (max-width: 550px) {
    .list-sponsors{
        grid-template-columns: repeat(3, 1fr);
    }
    h1{
        font-size: 50px;
        font-weight: 700;
    }
    .img-blue h1{
        font-size: 60px;
    }
    h2{
        font-size: 40px;
        font-weight: 700;
    }
    h3{
        font-size: 30px;
    }
    .cards-color .card{
        min-height: auto;
        height: auto;
        max-height: 800px;
    }
    .bg-dark h3{
        font-size: 15px;
    }
    p{
        font-size: 15px;
    }
}