footer *{
    color: white !important;
}
footer .card-transparent h5{
    font-size: 13px;
}
ul li{
    list-style: none;
    margin: 20px 0;
}
footer ul li a{
    text-decoration: none;
}
footer .nav .nav-link{
    font-size: 20px;
    background-color: #3f3e3d;
}
.navbar .logo{
    width: 100px;
    height: 100%;
}
.dropdown-menu h5{
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}
.dropdown-menu p{
    font-size: 13px;
}
.dropdown-menu span{
    font-size: 13px;
    font-weight: 500;
    color: #3f3e3d;
}
.dropdown-menu a{
    text-decoration: none;
    color: black;
}
.content-nav:hover{
    background-color: #fef7eb;
    transition: all 0.5s;
}
.card-icon-nav{
    border: 1px solid #9c9c9c;
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 48px;
    height: 48px;
}
.card-icon-nav img{
    width: 24px;
    height: 24px;
}
.card-light-violet img{
    height: 240px;
}
footer .color-grey{
    color: #D9D0CA !important;
}
footer .border-bottom{
    border-bottom: 1px solid #3f3e3d !important;
}