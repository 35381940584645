body{
    background-color: #fffbf4;
    font-family: "Roboto", sans-serif !important;
}
p{
    font-size: 18px;
}
h1{
    font-size: 72px;
    font-weight: 700;
}
h2{
    font-size: 56px;
    font-weight: 700;
}
h3{
    font-size: 44px;
}
span{
    color: #868686;
    font-size: 14px;
    font-weight: 500;
}
.color-grey{
    color: rgba(27, 27, 27, 0.85);
}
.bg-light-blue{
    background-color: #b1d8fc;
}
.bg-dark{
    background-color: #1b1b1b !important;
}
.bg-cream{
    background-color: #ffeba6;
    height: 1000px;
}
.bg-pink{
    background-color: #fef5e6;
}
.bg-white{
    background-color: white;
}
.bg-dark *{
    color: white;
}
.bg-dark .card-transparent span{
    color: darkgoldenrod !important;
    margin-bottom: 10px;
    font-size: 30px;
}
.bg-dark .card-transparent h5{
    font-size: 13px;
}
.container-bg-cream{
    background-image: url(https://deel-website-media-prod.s3.amazonaws.com/Coverage_Illustration_min_0a71b2d215.png);
    background-size: cover;
    background-position: center;
    height: 1000px;
}
.sponsor{
    height: 56px;
}
.sponsor img{
    max-height: 30px;
}
.list-sponsors{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    row-gap: 24px;
}
.link-white{
    background-color: white;
    color: #1b1b1b;
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 40px;
    border: 1px solid gray;
    transition: all 0.7s;
}
.link-white:hover{
    background-color: rgb(88, 88, 88);
    transition: all 0.7s;
    color: white;
}
.link-outline-white{
    font-weight: 500;
    text-decoration: none;
    color: #1b1b1b;
    transition: all 0.5s;
}
.link-outline-white:hover{
    transition: all 0.5s;
    color: #868686;
}
.link-black{
    background-color: #1b1b1b;
    text-decoration: none;
    color: white !important;
    padding: 8px 15px;
    border-radius: 40px;
    border: 1px solid gray;
    transition: all 0.7s;
}
.link-black:hover{
    background-color: rgb(88, 88, 88);
    transition: all 0.7s;
}
.mini-nav .nav-link{
    color: #868686;
}
.mini-nav .nav-link:hover{
    color: #1b1b1b;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white;
    background-color: #1b1b1b;
    border-color: #868686;
}
.tab-content h3{
    font-size: 30px;
}
.mini-cards-color .card{
    min-height: 250px;
}
.pricing .mini-cards-color .card{
    min-height: 400px;
}
.cards-color .card{
    height: 700px;
}
.cards-color .link-black{
    font-size: 15px;
}
.mini-cards-color .card-footer img{
    width: 50px;
    height: 50px;
}
.mini-cards-color .card h3{
    font-size: 20px;
}
.card-yellow{
    background-color: #ffe27c;
}
.card-light-blue{
    background-color: #C7E2FE;
}
.card-violet{
    background-color: #c4b1f9;
}
.card-light-violet{
    background-color: #e1d7fb;
}
.card-light-violet:hover{
    background-color: #c4b1f9;
}
.card-pink{
    background-color: #FEF0D8;
}
.mini-cards-color::-webkit-scrollbar {
	width: 2px;
    height: 4px;
	background: rgb(207, 207, 207);
}
.mini-cards-color::-webkit-scrollbar-thumb {
	background: #868686;
	border-radius: 10px;
}
.img-blue .row{
    height: 200px;
}
.img-blue p{
    font-size: 15px;
}
.img-blue h1{
    font-size: 70px;
    font-weight: 700;
}
.img-blue img{
    max-height: 130px;
    height: auto;
}
.bg-dark .icons{
    height: 70px;
}
.bg-dark h3{
    font-size: 30px;
}
.mini-nav{
    border: 1px solid #868686;
    border-radius: 30px;
}
.card-transparent{
    background-color: transparent;
}
.card-body{
    border-left: 1px solid #c0c0c0;
    margin: 0 15px;
}
.card-head .card-blue{
    background-color: #B1D8FC;
    border-radius: 50%;
}
.card-transparent .card-head h4{
    padding: 10px;
    margin-bottom: 0.5rem;
}
.icon-small{
    width: 50px;
}
.card-border-blue li span{
    color: green;
}
.card-border-blue li{
    font-size: 14px;
    margin: 10px 0;
}
.card-border-blue{
    border-top: 15px solid #b1d8fc;
    min-height: auto;
    height: 100%;
}
.card-border-blue p{
    font-size: 14px;
}
.img-icon{
    min-height: auto;
    height: 100%;
}
.img-icon p{
    font-size: 16px;
    color: rgba(27, 27, 27, 0.85);
}
.img-icon h4{
     font-size:  1.5rem;;
}
.img-icon img{
    height: 96px;
}